<template>
  <v-container grid-list-md pb-0>
    <v-layout wrap>
      <v-flex md6 pt-0>
        <v-layout wrap pb-4>
          <v-flex md3 sm3 xs12 pr-4 pl-0 py-0>
            <v-select
              :items="
                companies.map((c) => {
                  return { text: c.title, value: c.id };
                })
              "
              v-model="order.company_id"
              @change="loadRates"
              :label="$t('company_id')"
              height="1em"
            />
          </v-flex>

          <v-flex md3 sm3 xs12 pr-4 pl-0 py-0>
            <v-select
              :items="
                projects.map((p) => {
                  return { text: p.title, value: p.id };
                })
              "
              v-model="order.project_id"
              :label="$t('project_id')"
              height="1em"
            />
          </v-flex>

          <v-flex md3 sm3 xs12 pr-4 pl-0 py-0>
            <v-select
              :items="
                order_sources.map((s) => {
                  return { text: `${s.type}:${s.title}`, value: s.id };
                })
              "
              v-model="order.order_source_id"
              :label="$t('order_source_id')"
              height="1em"
            />
          </v-flex>

          <v-flex md3 sm3 xs12 pr-4 pl-0 mt-0 py-0>
            <v-text-field
              v-model="order.reference"
              :label="$t('reference')"
              :error-messages="errors.reference"
              height="1em"
            ></v-text-field>
          </v-flex>

          <v-flex md6 sm6 xs12 pr-4 pl-0 py-0 v-if="isAdmin">
            <v-select
              :items="
                locations.map((l) => {
                  return { text: l.reference, value: l.id };
                })
              "
              v-model="order.assigned_location_id"
              :label="$t('assigned_location_id')"
              height="1em"
            />
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex md12 sm12 xs12 pr-4 pl-0 py-0>
            <v-text-field
              v-model="order.to_address.name"
              :label="$t('name')"
              :error-messages="errors.to_address && errors.to_address.name"
              append-icon="language"
              @click:append="translateName()"
            ></v-text-field>
          </v-flex>
          <v-flex md5 sm5 xs12 pr-4 pl-0 py-0>
            <v-text-field
              :value="order.to_address && order.to_address.phone"
              @input="
                (phone) => {
                  order.to_address.phone = phone;
                }
              "
              :label="$t('phone')"
              :error-messages="errors.to_address && errors.to_address.phone"
              append-icon="language"
              @click:append="formatInternationalPhoneNumber()"
            ></v-text-field>
          </v-flex>
          <v-flex md7 sm7 xs12 pr-4 pl-0 py-0>
            <v-text-field
              :value="order.to_address && order.to_address.email"
              @input="
                (email) => {
                  order.to_address.email = email;
                }
              "
              :label="$t('email')"
              :error-messages="errors.to_address && errors.to_address.email"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout wrap pb-4>
          <v-flex md4 sm4 xs12 pr-4 pl-0 py-0>
            <v-autocomplete
              :value="order.to_address && order.to_address.country"
              @input="
                (country) => {
                  order.to_address.country = country;
                }
              "
              :items="countriesSelectList()"
              :label="$t('country')"
              :error-messages="errors.to_address && errors.to_address.country"
              @change="loadRates"
              height="1em"
            >
              <template v-slot:prepend>
                <flag
                  :iso="order.to_address && order.to_address.country"
                  :squared="false"
                ></flag> </template
            ></v-autocomplete>
          </v-flex>
          <v-flex md3 sm3 xs12 pr-4 pl-0 py-0>
            <v-text-field
              :value="order.to_address && order.to_address.zip"
              @input="
                (zip) => {
                  order.to_address.zip = zip;
                }
              "
              :label="$t('zip')"
              :error-messages="errors.to_address && errors.to_address.zip"
              @change="zipChanged"
              height="1em"
            ></v-text-field>
          </v-flex>
          <v-flex md5 sm5 xs12 pr-4 pl-0 py-0>
            <v-text-field
              :value="order.to_address && order.to_address.region"
              @input="
                (region) => {
                  order.to_address.region = region;
                }
              "
              :label="$t('region')"
              :error-messages="errors.to_address && errors.to_address.region"
              height="1em"
            ></v-text-field>
          </v-flex>
          <v-flex md4 sm4 xs12 pr-4 pl-0 py-0>
            <v-text-field
              :value="order.to_address && order.to_address.city"
              @input="
                (city) => {
                  order.to_address.city = city;
                }
              "
              :label="$t('city')"
              :error-messages="errors.to_address && errors.to_address.city"
              height="1em"
            ></v-text-field>
          </v-flex>
          <v-flex md8 sm8 xs12 pr-4 pl-0 py-0>
            <v-text-field
              :value="order.to_address && order.to_address.street"
              @input="
                (street) => {
                  order.to_address.street = street;
                }
              "
              :label="$t('street')"
              :error-messages="errors.to_address && errors.to_address.street"
              height="1em"
            ></v-text-field>
          </v-flex>
          <v-flex
            md12
            sm12
            xs12
            pr-4
            pl-0
            py-0
            v-if="
              order.carrier &&
                (!['RussianPost', 'DHL', 'UPS'].includes(order.carrier) ||
                  order.service === 'ParcelOnlineCombined')
            "
          >
            <v-autocomplete
              dense
              :loading="carrier_facilities_loading"
              :items="carrierFacilities"
              v-model="order.to_address.carrier_facility"
              :label="$t('carrier_facility')"
              :error-messages="
                errors.to_address && errors.to_address.carrier_facility
              "
              height="1em"
            ></v-autocomplete>
          </v-flex>
        </v-layout>
        <v-layout wrap pb-4>
          <v-flex md3 sm3 xs8 pr-4 pl-0 py-0>
            <v-text-field
              :value="getPrice(order)"
              @input="
                (e) => {
                  setPrice(order, e);
                }
              "
              :label="$t('price')"
              :error-messages="errors.price"
              height="1em"
            ></v-text-field>
          </v-flex>
          <v-flex md2 sm2 xs4 pr-4 pl-0 py-0>
            <v-select
              v-model="order.currency"
              :items="['RUB', 'USD', 'EUR', 'CNY']"
              :label="$t('currency')"
              :error-messages="errors.currency"
              height="1em"
            ></v-select>
          </v-flex>
          <v-flex md12 sm7 xs12 pr-4 pl-0 py-0>
            <v-select
              dense
              :loading="rates_loading"
              :items="rates"
              :value="selected_rate"
              @input="
                (e) => {
                  setCarrierService(order, e);
                }
              "
              :label="$t('carrier')"
              :error-messages="errors.rates"
              height="1em"
              :key="rates_loading"
            >
              <!-- We reference rate_loading in the :key field, so that it will re-render the whole select component
            when rates loading finishes. Without such re-render only 20 items of select will be displayed.
            Probably, this is because we append them dynamicaly and v-select cannot trace it. -->
              <template v-slot:prepend>
                <carrier-icon
                  v-if="order.carrier"
                  :carrier="order.carrier"
                  style="position: relative; top: -10px;"
                />
              </template>
              <template v-slot:selection="rate">
                {{ rate.item.service_title }} —&nbsp;
                <span v-if="rate.item.rate">
                  <strong>{{ rate.item.rate }}</strong>
                  {{
                    rate.item.currency ? $t(rate.item.currency + "_sign") : ""
                  }}
                </span>
                <span v-if="rate.item.delivery_days > 0">
                  ,&nbsp;<strong>{{ rate.item.delivery_days }}</strong
                  >&nbsp;{{ $t("days") }}
                </span>
                <span v-else class="red--text">
                  {{ rate.item.message }}
                </span>
              </template>
              <template v-slot:item="rate">
                <div :style="rate.item.carrier ? 'font-size: 110%;' : ''">
                  <carrier-icon :carrier="rate.item.carrier" />&nbsp;
                  {{ rate.item.service_title }} —
                  <span v-if="rate.item.rate">
                    <strong>{{ rate.item.rate }}</strong>
                    {{ $t(rate.item.currency + "_sign")
                    }}<span v-if="rate.item.delivery_days > 0"
                      >,&nbsp;<strong>{{ rate.item.delivery_days }}</strong
                      >&nbsp;{{ $t("days") }}</span
                    >
                  </span>
                  <span v-else class="red--text">
                    {{ rate.item.message }}
                  </span>
                </div>
              </template>
              <template v-slot:no-data>
                <v-list-tile>
                  <v-list-tile-content v-if="rates_loading">
                    {{ $t("loading") }}
                  </v-list-tile-content>
                  <v-list-tile-content v-else>
                    {{ $t("enter_postcode_to_view_rates") }}
                  </v-list-tile-content>
                </v-list-tile>
              </template>
            </v-select>
          </v-flex>
        </v-layout>
        <v-layout pb-0>
          <v-flex md12 pr-4 pl-0 py-0>
            <v-textarea
              auto-grow
              rows="1"
              v-model="order.comment"
              :label="$t('comment')"
              :error-messages="errors.comment"
            ></v-textarea>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex md6 xs12 pt-0 mt-0 px-0>
        <extra-line-items ref="lineItems" :order="order"></extra-line-items>
        <div class="subheading">
          {{ $t("netto_weight") }}:
          {{ lineItemsTotalWeight.toLocaleString($i18n.locale) }}&nbsp;{{
            $t("kg")
          }}
        </div>
        <div class="subheading">
          {{ $t("products") }}:
          {{ lineItemsTotalCost.toLocaleString($i18n.locale) }}&nbsp;{{
            $t(order.currency + "_sign")
          }}
        </div>
        <div class="subheading" v-if="order.price">
          {{ $t("delivery_cost") }}:
          {{
            (
              Math.round(order.price / 100.0) - lineItemsTotalCost
            ).toLocaleString($i18n.locale)
          }}&nbsp;{{ $t(order.currency + "_sign") }}
        </div>

        <v-layout mb-2>
          <v-checkbox
            v-model="order.prepaid"
            :true-value="true"
            :label="$t('prepaid')"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="order.prepaid_paid"
            :label="$t('prepaid_paid')"
            :disabled="!order.prepaid"
            hide-details
          ></v-checkbox>
        </v-layout>

        <v-layout mb-2>
          <v-checkbox
            v-model="order.archived"
            :label="$t('archived')"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="order.extra['dont_buy_shipment']"
            :label="$t('dont_buy_shipment')"
            hide-details
          ></v-checkbox>
        </v-layout>

        <v-layout
          mb-2
          v-if="
            order.to_address.country !== 'RU' &&
              (order.carrier === 'UPS' || order.carrier === 'DHL')
          "
        >
          <v-checkbox
            v-model="order.extra['charge_duties_to_shipper']"
            :label="$t('charge_duties_to_shipper')"
            hide-details
          ></v-checkbox>
        </v-layout>

        <v-layout mb-2> </v-layout>

        <v-expansion-panel v-if="order.versions" class="mt-3">
          <v-expansion-panel-content>
            <template v-slot:header
              ><div>{{ $t("changes_history") }}</div></template
            >
            <v-data-table
              :headers="[
                { text: $t('inserted_at'), value: 'inserted_at' },
                { text: $t('user'), value: 'user' },
                { text: $t('event'), value: 'event' },
              ]"
              :items="order.versions"
              :rows-per-page-items="[10, 20, 50]"
            >
              <template v-slot:items="{ item }">
                <td>{{ item.inserted_at }}</td>
                <td>{{ item.user }}</td>
                <td>{{ item.event }}</td>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <br />
        <h4 v-if="order.shipments && order.shipments.length > 0">
          {{ $t("existing_shipments") }}
        </h4>
        <v-chip
          v-for="sh in order.shipments"
          :key="sh.id"
          close
          @input="
            (e) => {
              deleteShipment(sh);
            }
          "
        >
          <v-avatar
            v-if="sh.tracking_code != null"
            v-html="'&nbsp;' + carrierImageTag(sh.selected_rate.carrier)"
          >
          </v-avatar>
          <v-avatar v-else class="error">E</v-avatar>
          <a :href="`/shipments/${sh.id}`">{{ sh.tracking_code || sh.id }}</a>
        </v-chip>
        <v-btn color="primary" @click="cloneOrder()" dark class="ml-0">{{
          $t("clone")
        }}</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import {
  isMobile,
  getPrice,
  setPrice,
  carrierImageTag,
  isAdmin,
} from "../functions.js";
import { countriesSelectList } from "../plugins/i18n.js";
import ExtraPostAPI from "../api.js";
import LineItems from "./LineItems.vue";
import CarrierIcon from "./CarrierIcon.vue";

import parsePhoneNumber from "libphonenumber-js";
const { Translate } = require("@google-cloud/translate").v2;
const projectId = "extrapost";
const translate = new Translate();

export default Vue.component("order", {
  name: "order",
  props: {
    order: {
      type: Object,
      default: {
        to_address: { country: "RU", zip: "", name: "" },
        currency: "",
        company_id: "",
        extra: {},
        line_items: [],
      },
    },
    errors: { type: Object, default: {} },
  },
  components: { "extra-line-items": LineItems, CarrierIcon },
  data() {
    return {
      on: false,
      rates: [],
      selected_rate: null,
      ratesItems: [],
      rates_loading: false,
      carrierFacilities: [],
      carrier_facilities_loading: false,
    };
  },
  watch: {
    order: function(order) {
      console.log("Order.watch", order);
      this.loadRates();
      this.loadCarrierFacilities();
      this.recalcTotals();
    },
  },
  computed: {
    ...mapState([
      "companies",
      "projects",
      "locations",
      "order_sources",
      "currentUser",
    ]),
    lineItemsTotalCost() {
      if (this.order.line_items === undefined) return 0;
      else
        return (
          this.order.line_items.reduce((acc, li) => {
            return acc + li.quantity * li.price;
          }, 0) / 100
        );
    },
    lineItemsTotalWeight() {
      if (this.order.line_items === undefined) return 0;
      else
        return (
          this.order.line_items.reduce((acc, li) => {
            return (
              acc +
              li.quantity *
                ((li.product && li.product.weight) || li.product_weight || 0)
            );
          }, 0) / 1000
        );
    },
  },
  created() {
    if (this.order.to_address === undefined)
      this.order.to_address = {
        country: "RU",
        zip: "",
        region: "",
        city: "",
        street: "",
        name: "",
      };
    if (this.order.currency === undefined)
      this.order.currency =
        (this.$store.state.currentUser &&
          this.$store.state.currentUser.extra.default_currency) ||
        "RUB";
    if (this.order.company_id === undefined) this.order.company_id = "";
    if (this.order.extra === undefined) this.order.extra = {};
    if (this.order.line_items === undefined) this.order.line_items = [];
    if (this.order.project_id === undefined)
      this.order.project_id =
        this.$store.state.currentUser &&
        this.$store.state.currentUser.extra.default_project_id;
  },
  methods: {
    carrierImageTag,
    countriesSelectList,
    getPrice,
    setPrice,
    setOrder(order) {
      this.order = order;
      this.$refs.lineItems.setLineItems(order.line_items);
      this.loadRates();
      this.loadCarrierFacilities();
      this.recalcTotals();
    },
    close() {
      console.log("Order close()");
    },
    save() {
      console.log("Order.save()", this.address.city);
      var self = this;
      ExtraPostAPI.put("addresses/" + this.address.id, {
        address: this.address,
      })
        .then((response) => {
          console.log("Address saving response", response);
          self.close();
        })
        .catch((error) => {
          if (error.response.data.errors) {
            self.errors = error.response.data.errors;
            console.log("Address saving errors:", error.response.data.errors);
          } else {
            console.log(error.response.data);
            self.$root.$emit("snack-message", error.response.data, "error");
          }
        });
    },
    zipChanged() {
      this.loadRates();
      this.loadCarrierFacilities();
    },
    loadRates() {
      // Clear previous results
      this.rates = [];
      this.ratesItems = [];

      console.log("loadRates", this.order);
      if (this.order.to_address === undefined || !this.order.to_address.zip)
        return;

      console.log(this.order);
      if (this.order.company_id && this.order.company_id !== "") {
        var company = this.companies.find(
          (comp) => comp.id === this.order.company_id
        );
        console.log(company);
        var self = this;
        var weight = this.calcOrderBruttoWeight(this.order);
        var value = this.order.price;
        var destination_param =
          this.order.to_address.country === "RU"
            ? `to=${this.order.to_address.zip}`
            : `country=${this.order.to_address.country}&to=${this.order.to_address.zip}`;

        this.rates_loading = true;
        var lastIndex = 0;
        ExtraPostAPI.get(
          `rates?from=${company.address.zip}&${destination_param}&weight=${weight}&value=${value}&company_id=${company.id}`,
          {
            onDownloadProgress: (event) => {
              console.log(event);

              var xhr = event.event.target;
              console.log(xhr);

              var currIndex = xhr.responseText.length;
              if (lastIndex === currIndex) return;
              var rates_chunk = xhr.responseText
                .substring(lastIndex, currIndex)
                .replace(/<\|>$/gm, "")
                .split("<|>")
                .map((r) => JSON.parse(r))
                .flat()
                .map((r) =>
                  Object.assign(r, { value: `${r.carrier}:${r.service}` })
                );

              lastIndex = currIndex;

              self.rates = self.rates.concat(rates_chunk);

              console.log("self.rates", self.rates);
              self.selected_rate = self.rates.find(
                (r) =>
                  r.carrier === self.order.carrier &&
                  r.service === self.order.service
              );

              console.log(self.selected_rate);
            },
          }
        ).then((response) => {
          // self.rates = response.data
          //   .split("<|>")
          //   .filter((chunk) => chunk !== "")
          //   .map((chunk) => JSON.parse(chunk))
          //   .filter((chunk) => chunk.length > 0)
          //   .flat()
          //   .map(rate => { rate.value = `${rate.carrier}:${rate.service}`; return rate; });

          self.rates_loading = false;
          console.log("final.rates", self.rates);
        });
      } else {
        this.errors.rates = this.$i18n.t("select_company_to_load_rates");
      }
    },
    loadCarrierFacilities() {
      if (
        this.order.carrier === undefined ||
        (this.order &&
          this.order.carrier &&
          ["RussianPost", "DHL", "UPS"].includes(this.order.carrier) &&
          this.order.service !== "ParcelOnlineCombined")
      ) {
        return;
      }
      this.carrier_facilities_loading = true;
      var self = this;
      this.carrierFacilities = [];
      ExtraPostAPI.get(
        `carrier_facilities/${this.order.carrier}/${this.order.to_address.zip}`,
        { headers: { "Cache-Control": "public", Pargma: "none" } }
      ).then((response) => {
        // Return only postomats for Russian Post
        self.carrierFacilities = response.data.carrier_facilities
          .filter((cf) => {
            return (
              cf.extra === undefined ||
              !cf.extra.type_code ||
              cf.extra.type_code === "ПОЧТОМАТ"
            );
          })
          .filter((cf) => {
            return (
              self.order.prepaid ||
              cf.extra === undefined ||
              cf.extra.cash_on_delivery === true
            );
          })
          .map(({ id: id, name: name, address: address, extra: extra }) => {
            return { text: `${name} ${extra.postamat ? 'ПОСТАМАТ ' : ''}(${address}) [${id}]`, value: id };
          });
        self.carrierFacilities.unshift({ text: "", value: null });
        self.carrier_facilities_loading = false;
      });
    },
    calcOrderBruttoWeight(order) {
      // Pre-calc order weight
      // Add 300 gramms for package
      // If no weight is given, guess as 500 gramms
      const package_weight = 300;
      return order.line_items
        .map(
          (li) =>
            (li.product_weight || (li.product && li.product.weight) || 500) *
            li.quantity
        )
        .reduce((a, b) => a + b, package_weight);
    },
    getCarrierService(order) {
      if (order.extra)
        return this.rates.find((r) => {
          r.carrier === order.carrier && r.service === order.service;
        });
      else return null;
    },
    setCarrierService(order, val) {
      var [carrier, service] = val.split(":");
      var oldCarrier = order.carrier;
      var oldService = order.service;
      order.carrier = carrier;
      order.service = service;
      if (carrier !== oldCarrier || service !== oldService) {
        this.loadCarrierFacilities();
      }
    },
    isMobile() {
      return isMobile();
    },
    recalcTotals() {
      if (this.order.line_items !== undefined) this.order.line_items.reduce;
    },
    formatInternationalPhoneNumber() {
      var number = parsePhoneNumber(
        this.order.to_address.phone,
        this.order.to_address.country
      );
      console.log(number);
      this.order.to_address.phone = number.formatInternational();
    },
    async translateName() {
      const [translation] = await translate.translate(
        this.order.to_address.name,
        "en"
      );
      console.log(translation);
    },
    deleteShipment(sh) {
      if (confirm(this.$i18n.t("sure_to_delete")))
        ExtraPostAPI.delete(`shipments/${sh.id}`)
          .then((response) => {
            this.order.shipments.splice(this.order.shipments.indexOf(sh), 1);
          })
          .catch((error) => {
            console.log(error);
            self.$root.$emit("snack-message", error.response.data, "error");
          });
    },
    cloneOrder() {
      var self = this;
      ExtraPostAPI.post("orders/" + this.order.id + "/clone").catch((error) => {
        self.$root.$emit("snack-message", error.response.data, "error");
      });
    },
    isAdmin() {
      return isAdmin(this);
    },
  },
});
</script>
